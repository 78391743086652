<template>
  <div class='checkbox-normal' @click='$emit("emit")'>
    <div>
      <i v-if='!isActive' :class="`fas fa-square`"></i>
      <i v-if='isActive' :class="`fas fa-check-square`"></i>
    </div>
    <p v-if='text'>
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    text: String,
  },
};
</script>

<style lang="scss">
  .checkbox-normal {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      i {
        color: $color-yellow;
        font-size: 18px;
        cursor: pointer;
      }
    }
    p {
      margin-left: 5px;
      font-size: 12px;
      cursor: pointer;
    }
  }
</style>
