<template>
  <button class="button-twitter-share">
    <a target="_blank" :href="createTwitterLink">
      <i class="fab fa-twitter"></i>
      {{ buttonTitle }}
    </a>
  </button>
</template>
<script>
export default {
  props: {
    buttonTitle: String,
    tweetText: String,
  },
  computed: {
    createTwitterLink() {
      const baseUrl = 'https://twitter.com/intent/tweet?text=';
      return `${baseUrl}${this.tweetText}`;
    },
  },
};
</script>
<style lang="scss">
.button-twitter-share {
  background-color: $color-twitter-blue;
  border: 1px solid $color-twitter-light-blue;
  border-radius: 3px;
  padding: 2px 0 0;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  min-width: 80px;
  cursor: pointer;
  transition: 0.1s;
  color: $color-black;
  a {
    text-decoration: none;
    color: $color-white;
    padding: 8px 10px;
    width: 100%;
  }
  p {
    padding: 0 10px;
    margin-top: 1px;
  }
}

.button-twitter-share:hover {
  background-color: $color-twitter-light-blue;
}
</style>
